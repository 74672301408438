import { PaymentInputOption } from 'src/utils/constants/quotes';

const makeInitialState = (values, parameter) => {
  return {
    ...values,
    distributorEquipment: values ? values.distributorEquipment : '',
    price: values ? values.price : '',
    residualPayment: Number(parameter.maxResidualValue) === 0 ? 1 : values.residualPayment,
    equipmentInsuranceIncluded: values
      ? values.equipmentInsuranceIncluded
      : false,
    term: values ? values.term : '',
    initialPayment: values ? values.initialPayment : '',
    gracePeriod: values ? values.gracePeriod : '',
    paymentInputOption: values
      ? values.paymentInputOption
      : PaymentInputOption.PERCENTAGE,
    isMsiModality: values ? values.isMsiModality : false
  };
};

export default makeInitialState;
