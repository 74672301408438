import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentLink from 'src/components/DocumentLink/DocumentLink';
import DetailRow from 'src/components/DetailRow';
import {
  formatAmountWithDecimals,
  formatPositiveAmount
} from 'src/utils/formatAmout';
import PropTypes from 'prop-types';

const DocumentEquipmentInfo = ({ equipment }) => {
  const { t } = useTranslation();
  const isMsiModality = equipment.contract?.isMsiModality;

  return (
    <>
      <DetailRow
        name={t('COMMON.PDF_INVOICE')}
        value={<DocumentLink link={equipment.invoicePdfUrl} />}
      />
      <DetailRow
        name={t('COMMON.XML_INVOICE')}
        value={<DocumentLink link={equipment.invoiceXmlUrl} />}
      />
      {isMsiModality ? (
        <>
          <DetailRow
            name={t('COMMON.PRICE_WITHOUT_DISCOUNT')}
            value={
              equipment.price
                ? formatPositiveAmount(equipment.price)
                : t('COMMON.NO_DATA')
            }
          />
          <DetailRow
            name={t('COMMON.MSI_DISCOUNT')}
            value={
              equipment.price
                ? formatPositiveAmount(equipment.contract.msiDiscount)
                : t('COMMON.NO_DATA')
            }
          />
          <DetailRow
            name={t('COMMON.PRICE')}
            value={
              equipment.price
                ? formatPositiveAmount(equipment.priceWithDiscount)
                : t('COMMON.NO_DATA')
            }
          />
          <DetailRow
            name={`${t('COMMON.IVA')} %`}
            value={
              equipment.iva
                ? formatAmountWithDecimals(equipment.iva)
                : t('COMMON.NO_DATA')
            }
          />
          <DetailRow
            name={t('EQUIPMENT_VIEW.PRICE_WITH_IVA')}
            value={
              equipment.priceWithIva
                ? formatPositiveAmount(equipment.priceWithIva)
                : t('COMMON.NO_DATA')
            }
          />
        </>
      ) : (
        <>
          <DetailRow
            name={t('COMMON.PRICE')}
            value={
              equipment.price
                ? formatPositiveAmount(equipment.price)
                : t('COMMON.NO_DATA')
            }
          />
          <DetailRow
            name={`${t('COMMON.IVA')} %`}
            value={
              equipment.iva
                ? formatAmountWithDecimals(equipment.iva)
                : t('COMMON.NO_DATA')
            }
          />
          <DetailRow
            name={t('EQUIPMENT_VIEW.PRICE_WITH_IVA')}
            value={
              equipment.priceWithIva
                ? formatPositiveAmount(equipment.priceWithIva)
                : t('COMMON.NO_DATA')
            }
          />
        </>
      )}
    </>
  );
};

DocumentEquipmentInfo.propTypes = {
  equipment: PropTypes.object.isRequired
};

export default DocumentEquipmentInfo;
