import React, { useCallback, useMemo } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, TextField } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import momentLocalTimezone from 'src/utils/momentLocalTimezone';
import generateNumbersList from 'src/utils/generateNumbersList';
import { getGracePeriodFromMonthDiff } from 'src/utils/contract';
import getMonthDiff from 'src/utils/date';
import { useSelector } from 'react-redux';
import { selectDistributorDetail } from 'src/store/selectors/distributorSelectors';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const StepFour = ({ data, handleNext, handleBack, resetRef }) => {
  const classes = useStyles();
  const parameter = useSelector(selectDistributorDetail);
  const initialState = useMemo(
    () =>
      makeInitialState({
        ...data,
        gracePeriod: Number(parameter.defaultGracePeriod)
      }),
    [data]
  );
  const { t } = useTranslation();

  const onNext = useCallback(
    values => {
      const rawMonthDiff = getMonthDiff(values.startDate, values.firstRentDate);
      const gracePeriod = getGracePeriodFromMonthDiff(rawMonthDiff);
      handleNext({ ...values, gracePeriod });
    },
    [handleNext]
  );
  return (
    <Formik
      initialValues={initialState}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onNext}
    >
      {({
        errors,
        handleSubmit,
        touched,
        values,
        setFieldValue,
        resetForm
      }) => {
        resetRef.current = () => resetForm();
        return (
          <form onSubmit={handleSubmit}>
            <Box className={classes.container}>
              <DatePicker
                label={t('CONTRACT_VIEW.SIGNATURE_DATE')}
                inputFormat="DD/MM/YYYY"
                value={values.startDate}
                margin="startDate"
                onChange={newValue => {
                  setFieldValue('startDate', moment(newValue));
                  setFieldValue(
                    'firstRentDate',
                    momentLocalTimezone(newValue).add(
                      values.gracePeriod + 30,
                      'days'
                    )
                  );
                  setFieldValue(
                    'payday',
                    Math.min(momentLocalTimezone(newValue).date(), 25)
                  );
                  setFieldValue(
                    'endDate',
                    momentLocalTimezone(newValue)
                      .add(values.gracePeriod + 30, 'days')
                      .add(data.term - 1, 'months')
                  );
                }}
                error={Boolean(touched.startDate && errors.startDate)}
                renderInput={params => (
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    {...params}
                    sx={{ width: '100%', margin: '16px 0' }}
                    FormHelperTextProps={{ sx: { color: 'red' } }}
                    helperText={touched.startDate && t(errors.startDate)}
                  />
                )}
              />

              <DatePicker
                label={t('CONTRACT_VIEW.FIRST_RENT_DATE')}
                value={values.firstRentDate}
                margin="firstRentDate"
                inputFormat="DD/MM/YYYY"
                maxDate={moment().add(4, 'months')}
                minDate={values.startDate}
                onChange={newValue => {
                  setFieldValue('firstRentDate', momentLocalTimezone(newValue));
                  setFieldValue(
                    'endDate',
                    momentLocalTimezone(newValue).add(data.term - 1, 'months')
                  );
                }}
                disabled={data.enableMsi}
                error={Boolean(touched.firstRentDate && errors.firstRentDate)}
                renderInput={params => (
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    {...params}
                    sx={{ width: '100%', margin: '16px 0' }}
                    FormHelperTextProps={{ sx: { color: 'red' } }}
                    helperText={
                      touched.firstRentDate && t(errors.firstRentDate)
                    }
                  />
                )}
              />
              <Autocomplete
                options={generateNumbersList(1, 25)}
                id="payday"
                label={t('NAVIGATION.PAY_DAY')}
                value={values.payday}
                fullWidth
                onInputChange={(e, value) => {
                  setFieldValue('payday', Number(value));
                }}
                sx={{ marginBottom: 1, marginTop: 2 }}
                helperText={touched.payday && t(errors.payday)}
                error={Boolean(touched.payday && errors.payday)}
              />
              <DatePicker
                label={t('CONTRACT_VIEW.LAST_RENT_DATE')}
                value={values.endDate}
                margin="endDate"
                disabled
                inputFormat="DD/MM/YYYY"
                error={Boolean(touched.endDate && errors.endDate)}
                renderInput={params => (
                  <TextField
                    InputProps={{
                      autoComplete: 'off'
                    }}
                    {...params}
                    sx={{ width: '100%', margin: '16px 0' }}
                    FormHelperTextProps={{
                      sx: {
                        color: 'red'
                      }
                    }}
                    helperText={touched.endDate && t(errors.endDate)}
                    error={Boolean(touched.endDate && errors.endDate)}
                  />
                )}
              />
            </Box>

            <Box
              className={classes.box}
              display="flex"
              justifyContent="space-between"
            >
              <Button
                className={classes.buttons}
                onClick={() => handleBack()}
                color="primary"
                variant="outlined"
              >
                {t('COMMON.BACK')}
              </Button>
              <Button
                className={clsx(classes.buttons, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
              >
                {t('COMMON.NEXT')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};
StepFour.propTypes = {
  data: PropTypes.object,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  resetRef: PropTypes.object
};

export default StepFour;
