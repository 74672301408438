import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  isLoadingSelector,
  successSelector
} from 'src/store/selectors/statusSelector';
import { LOAD_CLIENT_DETAIL, loadClientRatingValidation } from 'src/store/actions/clientActions';
import { selectClientDetail, selectRatingValidation } from 'src/store/selectors/clientSelectors';
import { useTranslation } from 'react-i18next';
import AlertMessage from 'src/components/Alert/Alert';

import Preloader from 'src/components/Preloader';
import { selectAuthSystem } from 'src/store/selectors/authSelectors';
import AlertSeverity from 'src/components/Alert/AlertEnums';
import UserType from 'src/utils/constants/auth';
import { getClientFinancialStatus } from 'src/utils/clientsHelper';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import GeneralClient from './GeneralDetail';
import ClientDocuments from './DocumentsDetail';
import ClientContactDetail from './ClientContactDetail';

const GeneralView = () => {
  const { t } = useTranslation();
  const system = useSelector(selectAuthSystem);
  const client = useSelector(selectClientDetail);
  const [openAlert, setOpenAlert] = useState(false);
  const loading = useSelector(isLoadingSelector([LOAD_CLIENT_DETAIL.action]));
  const success = useSelector(successSelector([LOAD_CLIENT_DETAIL.action]));
  const dispatch = useDispatch();
  const { id } = useParams();

  const disabledEdit = useMemo(() => {
    if (system === UserType.ADMIN || system === UserType.SUPER_ADMIN) {
      return false;
    }
    if (system === UserType.DISTRIBUTOR && client?.isEditable) {
      return false;
    }
    return true;
  }, [client, system]);

  const clientRatingValidation = useSelector(selectRatingValidation);

  useEffect(() => {
    dispatch(loadClientRatingValidation(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (client && client.pendingDocs > 0) {
      setOpenAlert(true);
    }
  }, [client]);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '0px 20px'
            }}
          >
            <Typography
              style={{ fontWeight: '400', textTransform: 'capitalize', display: 'inline-flex', alignItems: 'center' }}
              color="primary"
              variant="h1"
            >
              {t('COMMON.GENERAL')}
              <Box sx={{ marginLeft: 1, display: 'inline-flex', alignItems: 'center' }}>
                {getClientFinancialStatus(clientRatingValidation)}
              </Box>
            </Typography>
          </Box>
        </Grid>

        {success && !loading ? (
          <>
            {disabledEdit && (
              <Grid item xs={12}>
                <AlertMessage
                  open
                  severity={AlertSeverity.INFO}
                  title={t('COMMON.INFO')}
                  text={t('TOOLTIP_MESSAGES.CLIENT_NOT_EDITABLE')}
                  hideCloseIcon
                />
              </Grid>
            )}
            <Grid item xs={12} sx={!openAlert && { display: 'none' }}>
              <AlertMessage
                open={openAlert}
                handleClose={handleCloseAlert}
                severity={AlertSeverity.WARNING}
                title={t('COMMON.WARNING')}
                text={t('TOOLTIP_MESSAGES.CLIENT_HAS_PEDING_DOCS').replace(
                  '{parameter}',
                  client.pendingDocs
                )}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <GeneralClient client={client} isDisabledEdit={disabledEdit} />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <ClientContactDetail
                client={client}
                isDisabledEdit={disabledEdit}
              />
            </Grid>


            <Grid item xs={12} md={6} lg={4}>
              <ClientDocuments client={client} isDisabledEdit={disabledEdit} />
            </Grid>
          </>
        ) : (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              maxHeight: '70vh'
            }}
          >
            <Preloader style={{ height: '68vh' }} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default GeneralView;
