/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Box, Button, FormControl } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  CLEAR_CLIENT_OPTIONS,
  LOAD_CLIENTS,
  loadAllClients,
  loadClientDetail
} from 'src/store/actions/clientActions';
import {
  CLEAR_EQUIPMENTS,
  LOAD_EQUIPMENTS,
  loadEquipment
} from 'src/store/actions/equipmentsActions';
import { selectClientsList } from 'src/store/selectors/clientSelectors';
import { selectEquipmentList } from 'src/store/selectors/equipmentSelectors';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import { hasAddressCompleted } from 'src/utils/common';
import { selectAddressByType } from 'src/utils/address';
import AddressType from 'src/components/AddressDialog/AddressEnums';
import { getDistributorParameters } from 'src/store/actions/distributorActions';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';
import EquipmentAlert from './EquipmentAlert';

const StepOne = ({ data, handleNext, open, resetRef }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const initialState = useMemo(() => makeInitialState(data), [data]);
  const clientList = useSelector(selectClientsList);
  const equipmentList = useSelector(selectEquipmentList);
  const loadingClients = useSelector(isLoadingSelector([LOAD_CLIENTS.action]));
  const loadingEquipment = useSelector(
    isLoadingSelector([LOAD_EQUIPMENTS.action])
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      dispatch({ type: CLEAR_CLIENT_OPTIONS });
      dispatch(loadAllClients());
      dispatch(loadEquipment({ assignation: 'AVAILABLE' }, false));
      dispatch({ type: CLEAR_EQUIPMENTS });
    }
  }, [open]);

  const onNext = useCallback(
    values => {
      handleNext(values);
      if (values.distributorModel) {
        dispatch(getDistributorParameters(values.distributorModel.id));
      }
      dispatch(loadClientDetail(values.clientId));
    },
    [handleNext]
  );

  return (
    <Formik
      initialValues={initialState}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onNext}
    >
      {({
        errors,
        handleSubmit,
        touched,
        values,
        setFieldValue,
        resetForm
      }) => {
        resetRef.current = () => resetForm();
        return (
          <form onSubmit={handleSubmit}>
            <Box sx={{ mt: 3 }}>
              <EquipmentAlert
                equipments={equipmentList}
                loading={loadingEquipment}
              />
            </Box>

            <Box className={classes.container}>
              <FormControl
                fullWidth
                error={Boolean(touched.clientId && errors.clientId)}
              >
                <Autocomplete
                  options={clientList}
                  id="clientId"
                  label={t('COMMON.CLIENT')}
                  isLoading={loadingClients}
                  getOptionLabel={option => {
                    return option.fullName
                      ? `${option.fullName} - ID:${option.id}`
                      : '';
                  }}
                  value={clientList.find(
                    client => client.id === values.clientId
                  )}
                  fullWidth
                  sx={{ marginBottom: 2, marginTop: 2 }}
                  onChange={(_e, value) => {
                    setFieldValue('clientId', value?.id ?? '');
                    setFieldValue('clientName', value?.fullName ?? '');
                    setFieldValue('clientPersonType', value?.personType ?? '');
                    setFieldValue('avals', []);
                    setFieldValue(
                      'bankInformation',
                      value.bankInformation || {}
                    );
                    const address =
                      selectAddressByType(value.addresses, AddressType.WORK) ||
                      {};
                    if (hasAddressCompleted(address)) {
                      setFieldValue('equipmentLocation', {
                        street: address.street,
                        insideHomeNumber: address.insideHomeNumber,
                        externalHomeNumber: address.externalHomeNumber,
                        colonia: address.colonia,
                        city: address.city,
                        state: address.state,
                        zipCode: address.zipCode
                      });
                      setFieldValue('isExistingAddress', true);
                    }
                  }}
                  helperText={touched.clientId && t(errors.clientId)}
                  error={Boolean(touched.clientId && errors.clientId)}
                />
              </FormControl>

              <FormControl
                fullWidth
                error={Boolean(touched.equipmentId && errors.equipmentId)}
              >
                <Autocomplete
                  options={equipmentList}
                  id="equipmentId"
                  label={t('COMMON.EQUIPMENT')}
                  name="equipmentId"
                  loading={loadingEquipment}
                  getOptionLabel={option => (option ? `${option.id}` : '')}
                  value={equipmentList.find(
                    equipment => equipment.id === values.equipmentId
                  )}
                  fullWidth
                  onChange={(_e, value) => {
                    setFieldValue('equipmentId', value?.id ?? '');
                    setFieldValue('equipmentCost', value?.price ?? '');
                    setFieldValue(
                      'distributorModel',
                      value?.distributorModel ?? ''
                    );
                  }}
                  error={Boolean(touched.equipmentId && errors.equipmentId)}
                  helperText={touched.equipmentId && t(errors.equipmentId)}
                  sx={{ marginBottom: 2, marginTop: 2 }}
                />
              </FormControl>
            </Box>
            <Box
              className={classes.box}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                className={clsx(classes.buttons, classes.buttonMain)}
                type="submit"
                color="primary"
                variant="outlined"
              >
                {t('COMMON.NEXT')}
              </Button>
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};
StepOne.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  resetRef: PropTypes.object,
  handleNext: PropTypes.func
};

export default StepOne;
