import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormControl,
  TextField,
  Tooltip,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  selectDistributorCatalogs,
  selectDistributorDetail
} from 'src/store/selectors/distributorSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { isLoadingSelector } from 'src/store/selectors/statusSelector';
import {
  LOAD_DISTRIBUTOR_AGENTS,
  LOAD_DISTRIBUTOR_CATALOGS,
  LOAD_DISTRIBUTOR_PARAMETER,
  getDistributorParameters,
  loadDistributorEquipmentsCatalog
} from 'src/store/actions/distributorActions';
import { getEquipmentDescription } from 'src/utils/equipments';
import Autocomplete from 'src/components/Autocomplete/Autocomplete';
import DropdownMenu from 'src/components/DropdownMenu';
import MoneyMask from 'src/components/MoneyMask/MoneyMask';
import { getRentCalculator } from 'src/store/actions/quoteActions';
import EquipmentsAlert from 'src/views/quotes/CreateQuoteForm/CurrentStep/StepTwo/EquipmentsAlert';
import { selectRentCost } from 'src/store/selectors/quoteSelectors';
import {
  CLEAR_RENT_CALCULATOR,
  LOAD_RENT_CALCULATOR
} from 'src/store/constants/quotes';
import { validateTermAndInitialPayment } from 'src/utils/calculator';
import { formatAmount } from 'src/utils/formatAmout';
import { LoadingButton } from '@mui/lab';
import AlertMessage from 'src/components/Alert/Alert';
import { selectIndividualParameter } from 'src/store/selectors/parameterSelector';
import {
  getDefaultDaysForFirstRentList,
  TermValues,
  GracePeriodValues
} from 'src/utils/constants/distributors';
import { loadParameter } from 'src/store/actions/parameterActions';
import { Parameter } from 'src/utils/constants/parameters';
import PaymentMultipleInput from 'src/components/PaymentMultipleInput';
import { getInitialPaymentAmount } from 'src/utils/quotes';
import useStyles from '../styles';
import validationSchema from './schema';
import makeInitialState from './initialState';

const StepTwo = ({ data, handleNext, handleBack, resetRef }) => {
  const classes = useStyles();
  const parameter = useSelector(selectDistributorDetail);
  let enableCheckbox = false;
  const maxResidualValue = Number(parameter.maxResidualValue);
  const initialState = useMemo(
    () =>
      makeInitialState(
        {
          ...data,
          equipmentInsuranceIncluded: data.distributor.isInsuranceIncluded
            ? 'true'
            : 'false',
          gracePeriod: Number(parameter.defaultGracePeriod),
          residualPayment: 1,
          isMsiModality: false
        },
        parameter
      ),
    (enableCheckbox = parameter.enableMsi),
    [data, parameter]
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [disabledInputs, setDisabledInputs] = React.useState(false);
  const equipments = useSelector(selectDistributorCatalogs);
  const rentCost = useSelector(selectRentCost);
  const maxInitialPayment = useSelector(selectIndividualParameter);
  const rentCalculatorLoading = useSelector(
    isLoadingSelector([LOAD_RENT_CALCULATOR.action])
  );
  const isLoadingDistributorParameter = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_PARAMETER.action])
  );
  const isLoadingAgents = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_AGENTS.action])
  );
  const isLoadingEquipments = useSelector(
    isLoadingSelector([LOAD_DISTRIBUTOR_CATALOGS.action])
  );

  const rentCalculator = useCallback(values => {
    const newValues = {
      term: Number(values.term),
      equipmentCost: Number(values.price),
      initialPayment: getInitialPaymentAmount(
        values.price,
        values.initialPayment,
        values.paymentInputOption
      ),
      distributor: values.distributor,
      isMsiModality: values.isMsiModality
    };
    dispatch(getRentCalculator(newValues));
  }, []);

  const onNext = useCallback(
    async values => {
      handleNext({
        ...values
      });
    },
    [handleNext]
  );

  const handleSubmitForm = useCallback(
    (values, setFieldError) => {
      if (!rentCalculatorLoading && rentCost > 0) {
        onNext(values);
      } else {
        const isValidated = validateTermAndInitialPayment(
          values,
          setFieldError,
          {
            minTerm: parameter.minTerm,
            maxTerm: parameter.maxTerm,
            minInitialPaymentPercentage: parameter.minInitialPayment,
            maxInitialPaymentPercentage: Number(maxInitialPayment)
          },
          t
        );
        if (!isValidated) return;
        setDisabledInputs(true);
        rentCalculator(values);
      }
    },
    [parameter, rentCost, maxInitialPayment]
  );
  const handleResetRentCalculator = () => {
    setDisabledInputs(false);
    return dispatch({ type: CLEAR_RENT_CALCULATOR });
  };

  useEffect(() => {
    dispatch(loadDistributorEquipmentsCatalog(data.distributor.id, {}, false));
    dispatch(getDistributorParameters(data.distributor.id));
    dispatch(loadParameter(Parameter.MAXIMUM_INITIAL_PAYMENT_PERCENTAGE));
  }, [dispatch]);

  return (
    <>
      <Formik
        initialValues={initialState}
        enableReinitialize
        validationSchema={validationSchema(parameter)}
        onSubmit={(values, { setFieldError }) => {
          handleSubmitForm(values, setFieldError);
        }}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,
          setFieldValue,
          handleBlur,
          handleChange,
          resetForm
        }) => {
          resetRef.current = () => resetForm();
          return (
            <form onSubmit={handleSubmit}>
              <Box className={classes.container}>
                <EquipmentsAlert
                  equipments={equipments}
                  loading={isLoadingEquipments}
                  distributor={data.distributor}
                />
                {enableCheckbox && (
                  <Tooltip
                    title={
                      !enableCheckbox
                        ? t('COMMON.MONTHS_WITHOUT_INTEREST_MODE_DISABLED')
                        : t('COMMON.MONTHS_WITHOUT_INTEREST_MODE')
                    }
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isMsiModality}
                          onChange={e => {
                            const isChecked = e.target.checked;
                            setFieldValue('isMsiModality', isChecked);
                            setFieldValue(
                              'gracePeriod',
                              isChecked
                                ? GracePeriodValues.SAME_DAY_AS_CONTRACT_STARTS
                                : values.gracePeriod
                            );
                            setFieldValue(
                              'term',
                              isChecked
                                ? TermValues.TWELVE.toString()
                                : values.term
                            );
                            setFieldValue('residualPayment', '1');
                          }}
                          name="isMsiModality"
                          color="primary"
                          disabled={!enableCheckbox}
                        />
                      }
                      label={t('COMMON.MONTHS_WITHOUT_INTEREST_MODE')}
                    />
                  </Tooltip>
                )}
                <FormControl
                  fullWidth
                  error={Boolean(
                    touched.distributorEquipment && errors.distributorEquipment
                  )}
                >
                  <Autocomplete
                    options={equipments}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    disabled={isLoadingAgents || !equipments || disabledInputs}
                    id="distributorEquipment"
                    getOptionLabel={option =>
                      option ? getEquipmentDescription(option) : ''
                    }
                    value={values.distributorEquipment ?? ''}
                    fullWidth
                    onChange={(_e, value) => {
                      setFieldValue('distributorEquipment', value ?? '');
                      setFieldValue('price', value.price + value.iva ?? '');
                      handleResetRentCalculator();
                    }}
                    sx={{ marginBottom: 2, marginTop: 2 }}
                    label={
                      isLoadingAgents
                        ? `${t('COMMON.LOADING')} ${t('COMMON.EQUIPMENT')}`
                        : t('COMMON.EQUIPMENT')
                    }
                    helperText={
                      touched.distributorEquipment &&
                      t(errors.distributorEquipment)
                    }
                  />
                </FormControl>

                <TextField
                  InputProps={{
                    autoComplete: 'off',
                    inputComponent: MoneyMask
                  }}
                  error={Boolean(touched.price && errors.price)}
                  fullWidth
                  helperText={touched.price && t(errors.price)}
                  label={t('COMMON.PRICE')}
                  margin="normal"
                  name="price"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={
                    !data.distributor.isPriceEditable ||
                    isLoadingAgents ||
                    !equipments ||
                    disabledInputs
                  }
                  value={formatAmount(values.price, 2)}
                  variant="outlined"
                />

                <TextField
                  InputProps={{
                    autoComplete: 'off',
                    inputComponent: MoneyMask
                  }}
                  error={Boolean(
                    touched.residualPayment && errors.residualPayment
                  )}
                  fullWidth
                  helperText={
                    touched.residualPayment && t(errors.residualPayment)
                  }
                  label={t('CONTRACT_VIEW.RESIDUAL')}
                  margin="normal"
                  name="residualPayment"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.residualPayment}
                  disabled={maxResidualValue === 0 || values.isMsiModality}
                />

                <PaymentMultipleInput
                  firstInputName="paymentInputOption"
                  secondInputName="initialPayment"
                  disabled={
                    !equipments ||
                    !values.distributorEquipment ||
                    disabledInputs
                  }
                  label={t('COMMON.INITIAL_PAYMENT')}
                />

                <TextField
                  margin="normal"
                  fullWidth
                  name="term"
                  label="Plazo (meses)"
                  placeholder="Plazo (meses)"
                  type="number"
                  value={values.term}
                  onChange={handleChange}
                  error={Boolean(touched.term && errors.term)}
                  helperText={(touched.term && t(errors.term)) || ' '}
                  disabled={
                    isLoadingAgents ||
                    !equipments ||
                    disabledInputs ||
                    values.isMsiModality
                  }
                  sx={{ marginBottom: -2 }}
                />

                <FormControl fullWidth>
                  <DropdownMenu
                    onBlur={handleBlur}
                    label={t('COMMON.DAYS_FOR_FIRST_RENT')}
                    value={values.gracePeriod}
                    name="gracePeriod"
                    setValue={value => setFieldValue('gracePeriod', value)}
                    error={Boolean(touched.gracePeriod && errors.gracePeriod)}
                    errorText={touched.gracePeriod && t(errors.gracePeriod)}
                    list={getDefaultDaysForFirstRentList(
                      parameter.maxGracePeriod
                    )}
                    disabled={
                      isLoadingAgents ||
                      isLoadingDistributorParameter ||
                      disabledInputs ||
                      values.isMsiModality
                    }
                  />
                </FormControl>

                <Tooltip
                  disableFocusListener
                  title={t('COMMON.OBTAINED_FROM_DISTRIBUTOR_PARAMS')}
                >
                  <span>
                    <DropdownMenu
                      disabled
                      list={[
                        { key: 'true', name: t('COMMON.YES') },
                        { key: 'false', name: t('COMMON.NO') }
                      ]}
                      value={values.equipmentInsuranceIncluded}
                      setValue={value =>
                        setFieldValue(
                          'equipmentInsuranceIncluded',
                          value === 'true'
                        )
                      }
                      label={t(
                        'DISTRIBUTORS_VIEW.PARAMETERS.IS_INSURANCE_INCLUDED'
                      )}
                      error={Boolean(
                        touched.equipmentInsuranceIncluded &&
                          errors.equipmentInsuranceIncluded
                      )}
                      errorText={
                        touched.equipmentInsuranceIncluded &&
                        t(errors.equipmentInsuranceIncluded)
                      }
                    />
                  </span>
                </Tooltip>

                <br />

                {!rentCalculatorLoading && rentCost > 0 && (
                  <AlertMessage
                    open={!rentCalculatorLoading && rentCost > 0}
                    severity="info"
                    title={t('COMMON.RENT_VALUE')}
                    text={`$${formatAmount(rentCost)}`}
                    textStyle={{
                      fontSize: '1rem'
                    }}
                  />
                )}
              </Box>

              <Box
                className={classes.tableBox}
                display="flex"
                justifyContent="space-between"
              >
                <Button
                  className={classes.buttons}
                  onClick={
                    !rentCalculatorLoading && rentCost > 0
                      ? () => handleResetRentCalculator()
                      : () => handleBack()
                  }
                  color="primary"
                  variant="outlined"
                  disabled={isLoadingAgents}
                >
                  {!rentCalculatorLoading && rentCost > 0
                    ? t('COMMON.RECALCULATE')
                    : t('COMMON.BACK')}
                </Button>
                <LoadingButton
                  className={clsx(classes.buttons, classes.buttonMain)}
                  type="submit"
                  color="primary"
                  variant="outlined"
                  loading={rentCalculatorLoading}
                  loadingPosition="start"
                  disabled={isLoadingAgents || rentCalculatorLoading}
                >
                  {!rentCalculatorLoading && rentCost > 0
                    ? t('COMMON.NEXT')
                    : t('COMMON.RENT_CALCULATE')}
                </LoadingButton>
              </Box>
            </form>
          );
        }}
      </Formik>
    </>
  );
};
StepTwo.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  resetRef: PropTypes.object
};

export default StepTwo;
